import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import clsx from "clsx";
import React, { FC, useMemo } from "react";
import { useUserContext } from "../context/UserContext";
import { User } from "../reclaim-api/Users";
import { getUserMonogram } from "../utils/users";
import { Tooltip } from "./Tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      zIndex: theme.zIndex.appBar,
    },
    currentUser: {
      width: "100%",
    },
    currentUserIcon: {
      border: "1px solid rgba(0,0,0,0.125)",
      backgroundColor: theme.colors.white,
    },
    label: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    username: {
      color: theme.palette.common.white,
      marginLeft: -4,
      whiteSpace: "nowrap",
      "&.light": {
        color: theme.palette.common.white,
      },
      "&.dark": {
        color: theme.palette.text.primary,
      },
    },
    email: {

    },
    avatar: {
      height: 24,
      width: 24,
      "&$rightMargin": {
        marginRight: 18,
      },
    },
    rightMargin: {},
    paper: {
      margin: theme.spacing(1, 0),
      minWidth: "13em",
      color: theme.palette.getContrastText("#363740"),
      backgroundColor: "#363740",
    },
    menuList: {
      color: theme.palette.getContrastText("#363740"),
    },
    menuListIcon: {
      color: theme.palette.getContrastText("#363740"),
    },
  })
);

type CurrentUserProps = {
  className?: string;
  user?: User | null;
  color?: string;
  withUserName?: boolean;
};

export const CurrentUser: FC<CurrentUserProps> = ({ className, color = "light", withUserName = true }) => {
  const classes = useStyles();

  const [{ user, isAuthenticated }, actions] = useUserContext();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const monogram = useMemo(() => getUserMonogram(user), [user]);

  const handleLogout = () => actions?.logout();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(e: React.KeyboardEvent) {
    if (e.key !== "Tab") return;

    e.preventDefault();
    setOpen(false);
  }

  return (
    <Box className={clsx(classes.root, className)}>
      {!!user && (
        <Box className={classes.currentUser}>
          <Tooltip title={user.email || ""} placement="right">
            <Button
              ref={anchorRef}
              variant="text"
              size="large"
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              startIcon={
                <Badge badgeContent={0} color="secondary">
                  <Avatar
                    className={clsx(classes.avatar, withUserName && classes.rightMargin)}
                    src={user.avatarUrl}
                    alt={user.email}
                    title={user.email}
                  >
                    {monogram}
                  </Avatar>
                </Badge>
              }
            >
              {withUserName && (
                <Box className={classes.label}>
                  <Typography variant="body1" className={clsx(classes.username, color)}>
                    {user.name}
                  </Typography>
                </Box>
              )}
            </Button>
          </Tooltip>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            role={undefined}
            transition
            disablePortal
            modifiers={{
              offset: {
                enabled: false,
                offset: "100%",
              },
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: /^bottom/i.test(placement) ? "right top" : "right bottom",
                }}
              >
                <Paper className={classes.paper}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      id="menu-list-grow"
                      autoFocusItem={open}
                      className={classes.menuList}
                      onKeyDown={handleListKeyDown}
                    >
                      {isAuthenticated && (
                        <MenuItem onClick={handleLogout}>
                          <ListItemIcon>
                            <ExitToAppIcon fontSize="small" className={classes.menuListIcon} />
                          </ListItemIcon>
                          <Typography variant="inherit">Log out</Typography>
                        </MenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      )}
    </Box>
  );
};

export default CurrentUser;
